import { ServerEnvSchema } from './server/serverEnv'
import { getEnvStore } from './utils/envStore'

// NOTE: Be careful to not expose secret values from the server to the client here
const ClientEnvSchema = ServerEnvSchema.pick({
  SITE_URL: true,
  STACK_NAME: true,
  AUTH_TOKEN_ISSUER: true,
  NODE_ENV: true,
  ENABLE_SENTRY: true,
})

const clientEnv = ClientEnvSchema.parse(getEnvStore())

export { clientEnv, ClientEnvSchema }

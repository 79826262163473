import { z } from 'zod'

export const ServerEnvSchema = z.object({
  PORT: z
    .string()
    .default('3000')
    .transform((v) => parseInt(v, 10)),
  STACK_NAME: z.string().default('__localdev__'),
  PROJECT_NAME: z.string().default('saztunes'),

  NODE_ENV: z
    .enum(['development', 'production', 'test'])
    .default('development'),

  SITE_URL: z.string().url().default('https://dev.saztunes.com:3000'),

  FFMPEG_TOPIC_ARN: z.string(),

  BUCKET_NAME: z.string(),

  DEV_SERVER_CERT: z.string().optional(),
  DEV_SERVER_KEY: z.string().optional(),
  DEV_SERVER_HOST: z.string().optional(),

  AUTH_APPLE_KEY_ID: z.string(),
  AUTH_APPLE_PKCS8: z.string(),
  AUTH_APPLE_TEAM_ID: z.string(),
  AUTH_TOKEN_PKCS8: z.string(),
  AUTH_TOKEN_ISSUER: z.string().optional(),

  SUBSCRIPTION_APPLE_KEY_ID: z.string(),
  SUBSCRIPTION_APPLE_APPLE_ID: z.string().transform((v) => parseInt(v, 10)),
  SUBSCRIPTION_APPLE_ISSUER_ID: z.string(),
  SUBSCRIPTION_APPLE_PKCS8: z.string(),
  SUBSCRIPTION_GOOGLE_CREDENTIALS: z
    .string()
    .transform((text) => JSON.parse(text)),

  SUBSCRIPTION_STRIPE_STRIPE_CUSTOMER_ID: z.string(),
  SUBSCRIPTION_STRIPE_WEBHOOK_SECRET: z.string(),

  ENABLE_SENTRY: z.string().default('0'),
})

export type ServerEnv = z.infer<typeof ServerEnvSchema>
